import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Client } from "../interfaces/Client";
import { map, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})

export class LoginService
{
  constructor(private http : HttpClient){ }

  DoLogin(email: string, password: string) : Observable<Client>
  {
    return this.http
    .get<Client>(`${environment.apiUrl}/api/client/login/${email}/${password}`)
    .pipe(map((client: Client) => client));
  }
}
